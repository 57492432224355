/* You can add global styles to this file, and also import other style files */


.error {
  color: white;
  background-color: #97002e;
}

.success {
  color: white;
  background-color: #00708c;
}

.loading {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid rgb(11, 45, 113);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  margin-left: calc(50% - 60px);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}



.pagination>li>a {
  color: white !Important;
  background-color: rgb(11, 45, 113) !Important;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  color: white !Important;
  background-color: rgb(0, 102, 178) !Important;

}

.pagination>.active>a {
  color: white !Important;
  background-color: rgb(0, 102, 178) !Important;
}

.pagination>.active>a:hover {
  color: white !Important;
  background-color: rgb(0, 102, 178) !Important;
}

#site-specific-nav .mega-menu a {
  display: inline;
  color: #fff;
  font-size: 16px;
  line-height: 25px;
  font-family: 'GothamNarrowBook', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-decoration: none !important;
  border-bottom: 1px solid transparent;
}

#site-specific-nav .mega-menu a:hover {
  color: #fff;
  border-bottom-color: #fff !important;
}

#site-specific-nav .mega-menu h3 {
  color: #fff;
  margin: 0;
  text-align: left;
  padding: 0 0 0 33px;
}

#site-specific-nav .mega-menu ul {
  margin-top: 10px;
  margin-bottom: 10px;
}

#site-specific-nav .mega-menu li {
  position: relative;
  list-style-type: none;
  padding: 0 0 0 15px;
  line-height: 25px;
  margin: 0;
  text-align: left;
}

#site-specific-nav .mega-menu ul.arrows li:before {
  position: absolute;
  top: 4px;
  left: 0;
  font-family: 'cvx-icon', sans-serif;
  font-size: 20px;
  line-height: 20px;
  content: 'e617';
  color: #fff;
}

.really-large-blue-icon {
  font-size: 40px;
  color: #0066B2;
}

.large-blue-icon {
  font-size: 30px;
  color: #0066B2;
}

.country-selector {
  bottom: 8px !important;
}

input[type=tel] {
  box-shadow: none !important;
  border: none !important;
  padding: 0% !important;
  bottom: 4px;
  left: 95px;
}

.country-list-button {
  font-size: 0.8rem !important;
}

.mat-menu-content:not(:empty) {
  max-height: 250px;
}

.mat-form-field-wrapper {
  padding-bottom: 0px;
}

/* removes a double created by material and nextpage fighting */
.mat-checkbox-input {
  display: none !important;
}

.button-spinner {
  display: inline-block;
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.button-spinner .mat-progress-spinner circle,
.button-spinner .mat-spinner circle {
  stroke: #ffffff !important;
}

a.close-button:link,
a.close-button:visited,
a.close-button:active,
a.close-button:hover {
  text-decoration: none !important;
  color: black !important;
}

span.close-button {
  font-size: 24px;
  float: right;
  cursor: pointer;
}

.input-error {
  border: #e21836;
}

.disabled-button {
  background-color: lightgray;
}

.disabled-button:hover {
  background-color: lightgray;
  cursor: auto;
}

.mat-row:nth-child(4n+1) {
  background: #f3f4f8;
}

.mat-row:nth-child(4n+3) {
  background: #f3f4f8;
}

.customInputTypeStyleOverride {
  border: none !important;
  box-shadow: none !important;
  padding: 0px !important;
  margin: 0px !important;
}

/* removes a double created by material and nextpage fighting */
.mat-checkbox-input {
  display: none !important;
}

.button-spinner {
  display: inline-block;
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.button-spinner .mat-progress-spinner circle,
.button-spinner .mat-spinner circle {
  stroke: #ffffff !important;
}

.button-group-spaced {
  margin-right: 10px !important;
}

.cvx-button {
  margin-right: 5px !important;
  background-color: #0066B2 !important;
  color: white !important;
  border-radius: 0 !important;
}

.cvx-button:hover {
  background-color: #0B2D71 !important;
}

.cvx-button:disabled {
  background-color: #8C8F93 !important;
  color: #DBDCDD !important;
}

.cvx-button:disabled:hover {
  background-color: #8C8F93 !important;
  color: #DBDCDD !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #0B2D71;
}

.mat-mdc-row:nth-child(odd) {
  background-color: #f3f4f8;
  /* Set the background color for even rows */
}

.mat-mdc-row:nth-child(even) {
  background-color: #ffffff;
  /* Set the background color for odd rows */
}

mat-label {
  color: #0B2D71;
}

.alert-information #alert-shim-wrap #alert-shim {
  background: var(--medium-teal);
  color: white;
}

.alert-success #alert-shim-wrap #alert-shim,
.env-dev {
  background: var(--medium-green) !important;
  color: white !important;
}

.alert-warning #alert-shim-wrap #alert-shim,
.env-test,
.env-qa {
  background: var(--light-orange) !important;
  color: white !important;
}

.alert-danger #alert-shim-wrap #alert-shim,
.env-stage,
.env-prod {
  background: var(--dark-red) !important;
  color: white !important;
}

#alert-shim-close::after {
  font-size: 20px !important;
}

.mat-mdc-form-field-type-mat-select {
  padding-top: 5px;
}

mat-form-field:has(div.mat-mdc-text-field-wrapper > div.mat-mdc-form-field-flex > div.mat-mdc-form-field-infix > mat-select) {
  top: -5px;
  position: relative;
}

label:has(mat-label.no-brand) {
  font-family: Arial, sans-serif !important;
}

input.mdc-radio__native-control[type=radio] {
  position: absolute !important;
  width: 40px !important;
  height: 40px !important;
}

button.button.small {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.swal2-cancel.button {
  margin-left: 10px !important;
}

@media screen and (max-width: 768px) {
  table.tbl tr td:last-child {
    display: none;
  }
}

h3 {
  font-family: "GothamBold", Arial, sans-serif;
}

.card {
  margin-left: 5px;
  margin-right: 5px;
}

a.exception {
  font-family: 'GothamNarrowBook', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-decoration: underline !important;
}

/*align checkbox in mat-table*/
td.mat-mdc-cell:has(input[type='checkbox']),
th.mat-mdc-header-cell:has(input[type='checkbox']) {
  vertical-align: bottom !important;
  text-align: center !important;
}

/*makeSticky: start*/
.stickyHeader {
  top: 0;
  z-index: 99;
  position: sticky;
  background-color: white !important;
  padding-bottom: 5px;
}

table.stickyTableHeaders {
  top: 0;
  z-index: 99;
  position: relative;
}

table.stickyTableHeaders>thead>tr>th {
  top: 0;
  z-index: 99;
  position: sticky;
  background-color: white !important;
}

.stickyHeader+.dialog-wrapper {
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
}

.stickyHeader:has(> .close-button)+.adjustStickyHeader {
  /* need space for close button */
  padding-right: 10px;
  height: auto;
}

.stickyHeader+.adjustStickyHeader {
  /*
  need margin and displayBlock so labels display properly
  */
  display: block;
  margin-top: 5px;
}

.stickyHeader>filter-input {
  /*
  need padding and displayBlock so labels display properly
  */
  display: block;
  padding-top: 5px;
}

/*makeSticky: end*/

.float-right {
  float: right;
}

.missing-data-color {
  color: #E5601F !important;
}

.missing-data-background-color {
  background-color: #E5601F !important;
}

td.mat-mdc-cell:has(input[type='checkbox']) {
  vertical-align: bottom !important;
  text-align: center !important;
}

td.mat-mdc-cell:has(input[type='checkbox']) {
  vertical-align: bottom !important;
  text-align: center !important;
}